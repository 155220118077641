import styled, {css} from 'styled-components'
import {layout, space, color, typography} from 'styled-system'

const ACss = css`
  text-decoration: none;
  transition: opacity .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  &:hover {
    opacity: 0.9;
    transition: opacity .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  }
`

const AType = {
	default: css`
      ${ACss}
	`,
	navLink: css`
	  ${ACss}
      font-family: var(--font);
      font-weight: bold;
      font-size: 22px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
	  border-bottom-color: transparent;
	  transition: border-bottom-color .2s var(--standard-easing);
	  transform-origin: center center;
	  &:hover {
	    border-bottom: 3px solid var(--orange);
        transition: border-bottom-color .2s var(--standard-easing);
        transform-origin: center center;
	  }
	  &:focus {
        border-bottom: 3px solid var(--orange);
        transition: border-bottom-color .2s var(--standard-easing);
        transform-origin: center center;
	  }
	`
}

const A = styled.a`
	${props => props.type ? AType[props.type] : AType['default']}
  	${layout}
  	${space}
    ${color}
  	${typography}
`

export default A;