export default function LandingIntroductionBackgroundSvg() {
	return (
		<svg width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="100%" height="100%" fill="url(#paint0_linear)"/>
			<rect x="1479.79" y="679.061" width="938" height="185" rx="92.5" transform="rotate(-45 1479.79 679.061)" fill="white" fillOpacity="0.1"/>
			<path d="M148 0H-42V185L148 0Z" fill="url(#paint1_linear)"/>
			<circle r="221" transform="matrix(-1 0 0 1 -30 1108)" fill="#148080"/>
			<rect x="-172.59" y="398.616" width="406" height="80" rx="40" transform="rotate(-45 -172.59 398.616)" fill="white" fillOpacity="0.1"/>
			<path d="M1837 410H1947" stroke="#E06B35" strokeWidth="20" strokeLinecap="round"/>
			<path d="M1892.5 465L1892.5 355" stroke="#E06B35" strokeWidth="20" strokeLinecap="round"/>
			<path d="M207.148 64.148L170.892 27.8924" stroke="#279595" strokeWidth="20" strokeLinecap="round"/>
			<path d="M170.729 63.9846L206.985 27.7291" stroke="#279595" strokeWidth="20" strokeLinecap="round"/>
			<rect x="-109.025" y="477.625" width="304" height="60" rx="30" transform="rotate(-45 -109.025 477.625)" fill="white" fillOpacity="0.1"/>
			<rect x="1678.5" y="800.073" width="702" height="139" rx="69.5" transform="rotate(-45 1678.5 800.073)" fill="white" fillOpacity="0.1"/>
			<path d="M28.701 587.16V475.438L68.299 435.84" stroke="#D2CE3D" strokeWidth="12" strokeLinecap="round"/>
			<path d="M1919.5 826L1881 864.5V970.5" stroke="#D2CE3D" strokeWidth="20" strokeLinecap="round"/>
			<circle r="93.5" transform="matrix(-1 0 0 1 -30.5 1108.5)" fill="#188D8D"/>
			<circle cx="582" cy="143" r="10" fill="#279595"/>
			<circle cx="1655" cy="402" r="39" fill="#279595"/>
			<circle cx="98" cy="847" r="14" fill="#279595"/>
			<circle cx="1662" cy="819" r="7" fill="#279595"/>
			<circle cx="171" cy="608" r="27" fill="#279595"/>
			<circle cx="1753" cy="482" r="19" fill="#178C8C"/>
			<circle cx="49" cy="1388" r="35" fill="#178C8C"/>
			<circle cx="14" cy="870" r="9" fill="#178C8C"/>
			<path d="M207.148 64.148L170.892 27.8924" stroke="#279595" strokeWidth="20" strokeLinecap="round"/>
			<path d="M170.729 63.9846L206.985 27.7291" stroke="#279595" strokeWidth="20" strokeLinecap="round"/>
			<path d="M133.494 552.105C131.4 551.276 129.622 550.183 128.27 548.898C126.919 547.614 126.034 546.168 125.671 544.658L124.406 539.435C124.359 539.316 124.248 539.209 124.09 539.131C123.932 539.052 123.735 539.006 123.537 539C123.332 538.998 123.134 539.041 122.968 539.12C122.81 539.2 122.707 539.311 122.668 539.435L121.404 544.658C121.024 546.172 120.123 547.619 118.764 548.903C117.397 550.186 115.603 551.278 113.501 552.105L112.395 552.541C112.277 552.591 112.182 552.656 112.119 552.731C112.047 552.806 112.008 552.89 112 552.976C112 553.063 112.04 553.147 112.111 553.223C112.174 553.299 112.277 553.364 112.395 553.411L113.501 553.846C115.611 554.683 117.405 555.784 118.764 557.076C120.123 558.368 121.024 559.822 121.404 561.342L122.668 566.565C122.707 566.689 122.81 566.8 122.968 566.88C123.134 566.959 123.332 567.002 123.537 567C123.735 566.994 123.932 566.948 124.09 566.87C124.248 566.792 124.359 566.685 124.406 566.565L125.671 561.342C126.034 559.825 126.919 558.372 128.27 557.08C129.614 555.788 131.4 554.686 133.494 553.846L134.679 553.411C134.79 553.361 134.877 553.295 134.932 553.219C134.987 553.143 135.011 553.059 134.995 552.976C135.003 552.893 134.979 552.811 134.924 552.735C134.869 552.66 134.782 552.593 134.679 552.541L133.494 552.105Z" fill="#329999"/>
			<path d="M907.887 500.361C906.339 499.769 905.025 498.988 904.026 498.07C903.027 497.153 902.373 496.12 902.104 495.041L901.17 491.311C901.135 491.226 901.053 491.149 900.936 491.093C900.819 491.037 900.673 491.005 900.527 491C900.376 490.999 900.23 491.029 900.107 491.086C899.99 491.143 899.914 491.222 899.885 491.311L898.95 495.041C898.67 496.123 898.004 497.156 897 498.073C895.989 498.99 894.663 499.77 893.11 500.361L892.292 500.672C892.204 500.708 892.134 500.754 892.088 500.808C892.035 500.861 892.006 500.921 892 500.983C892 501.045 892.029 501.105 892.082 501.16C892.129 501.214 892.204 501.26 892.292 501.294L893.11 501.604C894.669 502.202 895.995 502.989 897 503.911C898.004 504.834 898.67 505.873 898.95 506.959L899.885 510.689C899.914 510.778 899.99 510.857 900.107 510.914C900.23 510.971 900.376 511.001 900.527 511C900.673 510.995 900.819 510.963 900.936 510.907C901.053 510.851 901.135 510.775 901.17 510.689L902.104 506.959C902.373 505.875 903.027 504.837 904.026 503.914C905.019 502.991 906.339 502.204 907.887 501.604L908.763 501.294C908.845 501.258 908.909 501.211 908.95 501.156C908.991 501.102 909.008 501.042 908.996 500.983C909.002 500.924 908.985 500.865 908.944 500.811C908.903 500.757 908.839 500.71 908.763 500.672L907.887 500.361Z" fill="#329999"/>
			<path d="M1841.36 885.914C1839.09 884.907 1837.15 883.579 1835.69 882.019C1834.22 880.46 1833.25 878.704 1832.86 876.871L1831.49 870.529C1831.43 870.384 1831.31 870.254 1831.14 870.159C1830.97 870.063 1830.76 870.008 1830.54 870C1830.32 869.998 1830.1 870.049 1829.92 870.146C1829.75 870.243 1829.64 870.378 1829.6 870.529L1828.22 876.871C1827.81 878.708 1826.83 880.465 1825.35 882.025C1823.87 883.584 1821.92 884.91 1819.63 885.914L1818.43 886.442C1818.3 886.503 1818.2 886.582 1818.13 886.674C1818.05 886.765 1818.01 886.866 1818 886.971C1818 887.076 1818.04 887.179 1818.12 887.271C1818.19 887.363 1818.3 887.442 1818.43 887.499L1819.63 888.028C1821.93 889.044 1823.88 890.381 1825.35 891.949C1826.83 893.518 1827.81 895.284 1828.22 897.129L1829.6 903.471C1829.64 903.622 1829.75 903.757 1829.92 903.854C1830.1 903.951 1830.32 904.002 1830.54 904C1830.76 903.992 1830.97 903.937 1831.14 903.842C1831.31 903.747 1831.43 903.617 1831.49 903.471L1832.86 897.129C1833.25 895.287 1834.22 893.523 1835.69 891.954C1837.15 890.385 1839.09 889.047 1841.36 888.028L1842.65 887.499C1842.77 887.439 1842.87 887.359 1842.93 887.266C1842.99 887.173 1843.01 887.072 1842.99 886.971C1843 886.87 1842.98 886.77 1842.92 886.679C1842.86 886.587 1842.76 886.506 1842.65 886.442L1841.36 885.914Z" fill="#329999"/>
			<path d="M1542.89 128.361C1541.34 127.769 1540.02 126.988 1539.03 126.07C1538.03 125.153 1537.37 124.12 1537.1 123.041L1536.17 119.311C1536.13 119.226 1536.05 119.149 1535.94 119.093C1535.82 119.037 1535.67 119.005 1535.53 119C1535.38 118.999 1535.23 119.029 1535.11 119.086C1534.99 119.143 1534.91 119.222 1534.88 119.311L1533.95 123.041C1533.67 124.123 1533 125.156 1532 126.073C1530.99 126.99 1529.66 127.77 1528.11 128.361L1527.29 128.672C1527.2 128.708 1527.13 128.754 1527.09 128.808C1527.04 128.861 1527.01 128.921 1527 128.983C1527 129.045 1527.03 129.105 1527.08 129.16C1527.13 129.214 1527.2 129.26 1527.29 129.294L1528.11 129.604C1529.67 130.202 1531 130.989 1532 131.911C1533 132.834 1533.67 133.873 1533.95 134.959L1534.88 138.689C1534.91 138.778 1534.99 138.857 1535.11 138.914C1535.23 138.971 1535.38 139.001 1535.53 139C1535.67 138.995 1535.82 138.963 1535.94 138.907C1536.05 138.851 1536.13 138.775 1536.17 138.689L1537.1 134.959C1537.37 133.875 1538.03 132.837 1539.03 131.914C1540.02 130.991 1541.34 130.204 1542.89 129.604L1543.76 129.294C1543.84 129.258 1543.91 129.211 1543.95 129.156C1543.99 129.102 1544.01 129.042 1544 128.983C1544 128.924 1543.98 128.865 1543.94 128.811C1543.9 128.757 1543.84 128.71 1543.76 128.672L1542.89 128.361Z" fill="#329999"/>
			<defs>
				<linearGradient id="paint0_linear" x1="960" y1="0" x2="960" y2="3216" gradientUnits="userSpaceOnUse">
					<stop stopColor="#178C8C"/>
					<stop offset="1" stopColor="#359A99"/>
				</linearGradient>
				<linearGradient id="paint1_linear" x1="54.6931" y1="-6.84298e-06" x2="-42.8729" y2="98.4664" gradientUnits="userSpaceOnUse">
					<stop stopColor="#2E9898"/>
					<stop offset="1" stopColor="#178C8C"/>
				</linearGradient>
			</defs>
		</svg>
	)
}