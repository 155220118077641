import styled, {css} from 'styled-components'
import {space, color, typography, layout} from 'styled-system'

const PCss = css`
  margin: 0;
  font-family: var(--secondary-font);
  font-size: 28px;
  letter-spacing: 0.05em;
  font-weight: 400;
  line-height: 40px;
  color: var(--text-dark);
`

const P = styled.p`
  	${PCss}
  	${space}
    ${color}
  	${typography}
  	${layout}
`

export default P;