import styled, {css} from 'styled-components'
import {border, layout, space} from 'styled-system'

const ButtonCss = css`
	border: none;
  	border-radius: 12px;
  	text-transform: uppercase;

  	font-family: var(--font);
  	font-weight: bold;
  	font-size: 18px;
  	letter-spacing: 0.05em;
  	transition: opacity .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  	cursor: pointer;
	&:hover {
	  opacity: 0.9;
	  transition: opacity .2s cubic-bezier(0.4, 0.0, 0.2, 1);
	}
`

const ButtonCssSize = {
	default: css`
	  	padding: 0 30px;
		height: 60px;	
	`,
	small: css`
	  	text-transform: capitalize;
	  	padding: 0 14px;
		height: 51px;
	`,
}

const ButtonCssTheme = {
	purple: css`
	  	${ButtonCss}
		background-color: var(--purple);
	  	color: var(--white);
	`,
	pink: css`
      	${ButtonCss}
		background-color: var(--pink);
      	color: var(--white);
	`,
	orange: css`
      	${ButtonCss}
		background-color: var(--orange);
	  	color: var(--white);	
	`,
	outlinedLight: css`
      	${ButtonCss}
        background-color: transparent;
		border: 2px solid var(--white);
      	color: var(--white);
	`,
	outlinedDark: css`
      	${ButtonCss}
        background-color: transparent;
		border: 2px solid var(--navy);
      	color: var(--navy);
	`
}

const Button = styled.button`
  
	${props => props.size ? ButtonCssSize[props.size] : ButtonCssSize['default']}
	${props => props.theme ? ButtonCssTheme[props.theme] : ButtonCssTheme['purple']}
  		
  	${space}
  	${layout}
  	${border}
`

export default Button;