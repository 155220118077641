import styled, {css} from 'styled-components'
import {layout, space, color, typography} from 'styled-system'

const LiCss = css`
  font-family: var(--secondary-font);
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.05em;
  color: var(--text-dark);
`

const Li = styled.li`
  	${LiCss}
  	${layout}
  	${space}
    ${color}
  	${typography}
`

export default Li;