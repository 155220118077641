import styled from 'styled-components'
import {border, layout, space} from 'styled-system'

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1232px;
  margin: 0 auto;
  ${space}
  ${layout}
  ${border}
`

export default ContentContainer;