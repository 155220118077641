import styled, {css} from 'styled-components'
import {space, color, typography, layout} from 'styled-system'

const H1Css = css`
  margin: 0;
  font-family: var(--font);
  font-weight: bold;
  font-size: 55px;
  letter-spacing: 0.05em;
  color: var(--text-dark);
`

const H1 = styled.h1`
  	${H1Css}
  	${space}
    ${color}
  	${typography}
  	${layout}
`

export default H1;