export default function TickIcon() {
	return (
		<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M25.3653 0.609209C24.5474 -0.20307 23.1183 -0.20307 22.3003 0.609209L8.98266 13.8138L3.69845 8.58281C3.28916 8.17759 2.74551 7.95472 2.16718 7.95472C2.16656 7.95472 2.16594 7.95472 2.16533 7.95472C1.58638 7.95472 1.04211 8.17943 0.633436 8.58527C0.224768 8.9911 0 9.53078 0 10.1048C0 10.6789 0.226625 11.218 0.635913 11.6238L7.45263 18.3719C7.86192 18.7771 8.40557 19 8.9839 19C9.56285 19 10.1071 18.7765 10.5164 18.3707L25.3653 3.64774C25.7746 3.2419 26 2.70222 26 2.12817C26 1.55411 25.774 1.01443 25.3653 0.609209Z" fill="url(#paint0_linear)"/>
			<defs>
				<linearGradient id="paint0_linear" x1="13" y1="0.000153492" x2="13" y2="24.7167" gradientUnits="userSpaceOnUse">
					<stop stopColor="#42D778"/>
					<stop offset="0.428" stopColor="#3DCA76"/>
					<stop offset="1" stopColor="#34B171"/>
				</linearGradient>
			</defs>
		</svg>
	)
}