export function GraphQlIcon() {
	return (
		<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="66" height="66" rx="9" fill="#2B2B2B"/>
			<path d="M15.8964 45.3187L14.1712 44.3227L33.3892 11.0371L35.1144 12.0331L15.8964 45.3187Z" fill="#E10098"/>
			<path d="M13.7762 41.6641H52.2122V43.6561H13.7762V41.6641Z" fill="#E10098"/>
			<path d="M33.762 53.883L14.5368 42.783L15.5328 41.0579L34.758 52.1579L33.762 53.883ZM50.4629 24.9535L31.2377 13.8535L32.2337 12.1284L51.4589 23.2284L50.4629 24.9535Z" fill="#E10098"/>
			<path d="M15.5381 24.9461L14.5421 23.2211L33.7674 12.1211L34.7634 13.8462L15.5381 24.9461Z" fill="#E10098"/>
			<path d="M50.1082 45.3192L30.8902 12.0336L32.6153 11.0376L51.8333 44.3232L50.1082 45.3192ZM15.3 21.8996H17.292V44.0996H15.3V21.8996ZM48.708 21.8996H50.7V44.0996H48.708V21.8996Z" fill="#E10098"/>
			<path d="M33.4227 53.0399L32.5527 51.5329L49.2735 41.8789L50.1435 43.3857L33.4227 53.0399Z" fill="#E10098"/>
			<path d="M53.3402 44.7479C52.1882 46.7519 49.6202 47.4359 47.6162 46.2839C45.6122 45.1319 44.9282 42.5639 46.0802 40.5599C47.2322 38.5559 49.8002 37.8719 51.8042 39.0239C53.8202 40.1879 54.5042 42.7439 53.3402 44.7479ZM19.9082 25.4399C18.7562 27.4439 16.1882 28.1279 14.1842 26.9759C12.1802 25.8239 11.4962 23.2559 12.6482 21.2519C13.8002 19.2479 16.3682 18.5639 18.3722 19.7159C20.3762 20.8799 21.0602 23.4359 19.9082 25.4399ZM12.6602 44.7479C11.5082 42.7439 12.1922 40.1879 14.1962 39.0239C16.2002 37.8719 18.7562 38.5559 19.9202 40.5599C21.0722 42.5639 20.3882 45.1199 18.3842 46.2839C16.3682 47.4359 13.8122 46.7519 12.6602 44.7479ZM46.0922 25.4399C44.9402 23.4359 45.6242 20.8799 47.6282 19.7159C49.6322 18.5639 52.1882 19.2479 53.3522 21.2519C54.5042 23.2559 53.8202 25.8119 51.8162 26.9759C49.8122 28.1279 47.2442 27.4439 46.0922 25.4399ZM33.0002 56.4959C30.6842 56.4959 28.8122 54.6239 28.8122 52.3079C28.8122 49.9919 30.6842 48.1199 33.0002 48.1199C35.3162 48.1199 37.1882 49.9919 37.1882 52.3079C37.1882 54.6119 35.3162 56.4959 33.0002 56.4959ZM33.0002 17.8799C30.6842 17.8799 28.8122 16.0079 28.8122 13.6919C28.8122 11.3759 30.6842 9.50391 33.0002 9.50391C35.3162 9.50391 37.1882 11.3759 37.1882 13.6919C37.1882 16.0079 35.3162 17.8799 33.0002 17.8799Z" fill="#E10098"/>
		</svg>
	)
}

export function MagentoIcon() {
	return (
		<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="66" height="66" rx="9" fill="#2B2B2B"/>
			<path d="M35.5015 23.4658V49.0389L32.988 50.5759L30.4726 49.0307V23.4856L23.9594 27.4979V49.3674L32.988 54.8947L42.093 49.3281V27.4839L35.5015 23.4658ZM32.988 10L14 21.5102V43.3826L18.9315 46.2893V24.4149L32.9918 15.861L47.0652 24.4024L47.1232 24.4355L47.117 46.2566L52 43.3826V21.5102L32.988 10Z" fill="#EC6737"/>
		</svg>
	)
}

export function NextJsIcon() {
	return (
		<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="66" height="66" rx="9" fill="#2B2B2B"/>
			<path d="M20.7373 26.3096H29.4939V27H21.5402V32.1952H29.0195V32.8856H21.5402V38.5895H29.585V39.2798H20.7373V26.3096ZM30.2782 26.3096H31.2087L35.3316 32.0135L39.5456 26.3096L45.2774 19.0645L35.8606 32.613L40.7132 39.2798H39.7462L35.3316 33.2125L30.8987 39.2798H29.95L34.839 32.613L30.2785 26.3096H30.2782ZM41.0599 27V26.3096H51.0384V27H46.4415V39.2795H45.6386V27H41.0602H41.0599ZM9.78265 26.3096H10.7861L24.6215 46.8439L18.9039 39.2798L10.6217 27.2905L10.5852 39.2798H9.78265V26.3096ZM50.9579 38.381C50.7935 38.381 50.6706 38.255 50.6706 38.0923C50.6706 37.9293 50.7935 37.8033 50.9576 37.8033C51.1236 37.8033 51.2446 37.9293 51.2446 38.0923C51.2446 38.255 51.1236 38.381 50.9576 38.381H50.9579ZM51.7464 37.621H52.1761C52.1821 37.8518 52.3521 38.0068 52.6017 38.0068C52.8809 38.0068 53.039 37.8403 53.039 37.528V35.5505H53.4763V37.5298C53.4763 38.0923 53.1484 38.4162 52.6055 38.4162C52.0962 38.4162 51.7467 38.102 51.7467 37.621H51.7464ZM54.0481 37.5961H54.4816C54.5187 37.8615 54.7803 38.0301 55.1572 38.0301C55.5083 38.0301 55.7661 37.8497 55.7661 37.6017C55.7661 37.3886 55.6023 37.2604 55.2292 37.1733L54.8661 37.0862C54.3568 36.968 54.1242 36.7234 54.1242 36.3125C54.1242 35.8141 54.5341 35.4824 55.149 35.4824C55.7212 35.4824 56.1393 35.8141 56.1644 36.2851H55.7388C55.6979 36.0275 55.4716 35.8663 55.1434 35.8663C54.7976 35.8663 54.5675 36.0312 54.5675 36.2832C54.5675 36.4829 54.7158 36.5974 55.083 36.6827L55.3933 36.7583C55.9711 36.8921 56.2094 37.1248 56.2094 37.5454C56.2094 38.0805 55.7913 38.4162 55.1239 38.4162C54.4992 38.4162 54.0793 38.0961 54.0481 37.5958V37.5961Z" fill="white"/>
		</svg>
	)
}

export function ReactJsIcon() {
	return (
		<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="66" height="66" rx="9" fill="#2B2B2B"/>
			<g clipPath="url(#clip0)">
				<path d="M32.5002 38.0078C35.0107 38.0078 37.0459 35.9894 37.0459 33.4997C37.0459 31.01 35.0107 28.9917 32.5002 28.9917C29.9897 28.9917 27.9546 31.01 27.9546 33.4997C27.9546 35.9894 29.9897 38.0078 32.5002 38.0078Z" fill="#61DAFB"/>
				<path d="M32.4999 42.7366C45.9709 42.7366 56.8913 38.6015 56.8913 33.5006C56.8913 28.3997 45.9709 24.2646 32.4999 24.2646C19.029 24.2646 8.10864 28.3997 8.10864 33.5006C8.10864 38.6015 19.029 42.7366 32.4999 42.7366Z" stroke="#61DAFB"/>
				<path d="M24.4345 38.1182C31.17 49.6878 40.2411 56.9993 44.6955 54.4489C49.1498 51.8985 47.3006 40.4519 40.5652 28.8823C33.8297 17.3126 24.7585 10.0011 20.3042 12.5516C15.8498 15.102 17.699 26.5486 24.4345 38.1182Z" stroke="#61DAFB"/>
				<path d="M24.4346 28.8826C17.6991 40.4523 15.8499 51.8988 20.3042 54.4493C24.7586 56.9997 33.8297 49.6882 40.5652 38.1186C47.3007 26.549 49.1499 15.1024 44.6955 12.5519C40.2412 10.0015 31.17 17.313 24.4346 28.8826Z" stroke="#61DAFB"/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="51" height="45" fill="white" transform="translate(7 11)"/>
				</clipPath>
			</defs>
		</svg>
	)
}