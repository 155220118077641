import styled from 'styled-components'
import ContentDivider from "../../components/molecules/contentDivider";
import DesignPreviewElements from "../../components/molecules/DesignPreviewElements";
import ContentContainer from "../../components/atoms/ContentContainer";
import Aside from "../../components/atoms/Aside";
import H1 from "../../components/atoms/H1";
import P from "../../components/atoms/P";
import FlexDiv from "../../components/atoms/FlexDiv";
import ImageSrc from "../../assets/images/Devices@2x.png"
import Img from "../../components/atoms/Img";

export default function DesignPreview() {
	return (
		<StyledDesignPreview id={'frontEnd'}>
			<ContentDivider fillColour={'#ECF2F6'} />
			<DesignPreviewElements />
			<ContentContainer zIndex={'2'}>
				<Aside>
					front end
				</Aside>
				<H1 color={'var(--text-dark)'} margin={'24px 0 0 0'}>
					Boost Conversion Rates, Beautifully
				</H1>
				<P color={'var(--text-dark)'} margin={'24px 0 0 0'}>
					With a clean thoughtful design to showcase your product,
					and a continuously improving user experience, Aloe storefront
					ensures a pleasurable and optimised shopping journey.
				</P>
				<FlexDiv position={'absolute'} left={'0'} right={'0'} zIndex={'1'} width={'100%'} overFlow={'hidden'} justifyContent={'center'} margin={'0 0 0 0'} >
					<Img src={ImageSrc} alt="mobile devices displaying clean designed UI" width={'100%'}/>
				</FlexDiv>
			</ContentContainer>
		</StyledDesignPreview>
	)
}

const StyledDesignPreview = styled.div`
  	position: relative;
  	height: 2535px;
  	background-color: var(--light-gray);
`

const StyledDesignDevicesImg = styled.img`
	max-width: 100%;
`