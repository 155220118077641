import styled, {css} from 'styled-components'
import {layout, space, color, typography} from 'styled-system'

const UlCss = css`
  margin: 0;
  padding: 0;
`

const UlType = {
	default: css`
		${UlCss}
	`,
	noBullet: css `
	  	${UlCss}
		list-style: none;
	`
}

const Ul = styled.ul`
  	${props => props.type ? UlType[props.type] : UlType['default']}
  	${layout}
  	${space}
    ${color}
  	${typography}
`

export default Ul;