import styled from 'styled-components'
import {layout, space, color, typography, flexbox} from 'styled-system'

const InlineUl = styled.ul`
  	margin: 0;
  	padding: 0;
  	display: flex;
  	list-style: none;
  	${layout}
  	${space}
    ${color}
  	${typography}
  	${flexbox}
`

export default InlineUl;