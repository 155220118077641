import styled from 'styled-components'
import Navigation from "../core/components/layout/navigation/Navigation";
import LandingIntroduction from "../core/page-components/landing-introduction/LandingIntroduction";
import DesignPreview from "../core/page-components/design-preview/designPreview";
import SupportedTechnology from "../core/page-components/supported-technology/SupportedTechnology";
import Features from "../core/page-components/features/Features";
import Integrations from "../core/page-components/integrations/Integrations";
import Clients from "../core/page-components/clients/Clients";
import Footer from "../core/components/layout/footer/Footer";

export default function SinglePage() {
	return (
		<SinglePageWrap>
			<Navigation />
			<LandingIntroduction />
			<DesignPreview />
			<SupportedTechnology />
			<Features />
			<Integrations />
			<Clients />
			<Footer />
		</SinglePageWrap>
	)
}

const SinglePageWrap = styled.div``