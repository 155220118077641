import Div from "../atoms/Div";
import AloeLogo from "../svgs/AloeLogo";


export default function CompanyLogo({width, height, margin}) {
	return (
		<Div width={width} height={height} margin={margin ? margin : 0}>
			<AloeLogo/>
		</Div>
	)
}
