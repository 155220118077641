import styled, {css} from 'styled-components'
import {space, color, typography, layout} from 'styled-system'

const SpanCss = css`
  font-family: var(--secondary-font);
  font-size: 28px;
  letter-spacing: 0.05em;
  font-weight: 700;
`

const SpanType = {
	default: css`
      ${SpanCss}
	`,
	secondary: css`
      font-family: var(--tertiary-font);
      font-style: normal;
      font-size: 18px;
      color: var(--span-dark);
	  display: block;
	`,
	light: css`
      font-family: var(--secondary-font);
      font-size: 24px;
      letter-spacing: 0.05em;
      color: var(--text-dark);
	`
}

const Span = styled.span`
  	${props => props.type ? SpanType[props.type] : SpanType['default']}
  	${space}
    ${color}
  	${typography}
  	${layout}
`

export default Span;