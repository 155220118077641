import styled from 'styled-components'
import ContentContainer from "../../components/atoms/ContentContainer";
import Aside from "../../components/atoms/Aside";
import H1 from "../../components/atoms/H1";
import P from "../../components/atoms/P";
import Div from "../../components/atoms/Div";
import FlexDiv from "../../components/atoms/FlexDiv";
import Ul from "../../components/atoms/Ul";
import Li from "../../components/atoms/Li";
import TickIcon from "../../components/svgs/TickIcon";
import Span from "../../components/atoms/Span";

export default function Features() {
	const b2cFeatureList = [
		'Add to Home Screen',
		'Flexible Mega Menu and Site Navigation ',
		'Search Including Suggestions',
		'Store Locator',
		'Multiple Pricing Variants for Specials',
		'Whatsapp and Email Notifications',
		'Newsletters',
		'Abandoned Cart',
		'SLA Dashboard',
		'Metrics and Exception reporting',
		'SEO Support'
	]
	const b2bFeatureList = [
		'Custom Pricing Groups',
		'Account Payments',
		'In store Integrations',
		'B2B Account Applications',
		'“I’ve Paid” Recon Module',
		'Whatsapp for Business',
		'Bulk ordering options'
	]
	return (
		<StyledFeatures id={'platform'}>
			<ContentContainer padding={'235px 0 240px 0'}>
				<Aside>
					platform
				</Aside>
				<H1 color={'var(--white)'} margin={'24px 0 0 0'}>
					Features
				</H1>
				<P color={'var(--white)'} margin={'28px 0 0 0'}>
					Aloe Storefront is for businesses that need more than what Shopfiy
					and Wordpress offer with regard to custom integrations with their
					ERPs. Aloe Storefront offers  a full and growing set of features
					for both B2B and B2C commerce.
				</P>
				<FlexDiv
					backgroundColor={'var(--white)'}
					borderRadius={'24px'}
					boxShadow={'2px 2px 20px rgba(0, 0, 0, 0.08), 0px 0px 30px rgba(0, 0, 0, 0.04)'}
					padding={'79px 96px'}
					margin={'37px 0 0 0'}
					justifyContent={'space-between'}
				>
					<Div width={'558px'}>
						<Aside>
							B2C FEATURES
						</Aside>
						<FeatureList list={b2cFeatureList}/>
					</Div>
					<Div width={'380px'}>
						<Aside>
							B2B FEATURES
						</Aside>
						<FeatureList list={b2bFeatureList}/>
					</Div>
				</FlexDiv>
			</ContentContainer>
		</StyledFeatures>
	)
}

const StyledFeatures = styled.div`
  	padding: 0 0 0 0;
  	background-color: var(--slate);
`

function FeatureList({list}) {
	return (
		<Ul type={'noBullet'} margin={'37px 0 0 0'}>
			{list.map(item => {
				return (
					<Li margin={'16px 0 0 0'} key={item}>
						<TickIcon/>
						<Span type={'light'} margin={'0 0 0 20px'}>{item}</Span>
					</Li>
				)
			})}
		</Ul>
	)
}