import './styles/vars.scss';
import SinglePage from "./pages/SinglePage";

function App() {
  return (
    <div className="App">
      <SinglePage />
    </div>
  );
}

export default App;
