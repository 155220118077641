import styled from 'styled-components'
import ContentContainer from "../../atoms/ContentContainer";
import CompanyLogo from "../../molecules/CompanyLogo";
import A from "../../atoms/A";
import FlexDiv from "../../atoms/FlexDiv";
import Li from "../../atoms/Li";
import Button from "../../atoms/Button";
import InlineUl from "../../atoms/InlineUl";
import {useEffect, useState} from "react";

export default function Navigation() {
	const [scrollAmount, setScrollAmount] = useState(0)

	function listenForScroll() {
		window.addEventListener('scroll', checkScrollAmount);
	}

	function checkScrollAmount() {
		setScrollAmount(window.scrollY)
	}

	useEffect(() => {
		listenForScroll();
	})

	return (
		<NavWrap style={scrollAmount > 100 ? {backgroundColor: 'var(--light-aqua)'}: {backgroundColor: "transparent"}}>
			<ContentContainer justifyContent={"space-between"} >
				<FlexDiv justifyContent={"space-between"} >
					<A href={'#home'}>
						<CompanyLogo width={'292px'} height={'66px'} margin={'0 0 -13px 0'} />
					</A>
					<InlineUl alignItems={'flex-end'}>
						<Li margin={'0 32px 0 0'}>
							<A href={'#frontEnd'} color={'var(--white)'} type={'navLink'}>
								UI
							</A>
						</Li>
						<Li margin={'0 32px 0 0'}>
							<A href={'#technology'} color={'var(--white)'} type={'navLink'}>
								Technology
							</A>
						</Li>
						<Li margin={'0 32px 0 0'}>
							<A href={'#platform'} color={'var(--white)'} type={'navLink'}>
								Platform
							</A>
						</Li>
						<Li margin={'0 32px 0 0'}>
							<A href={'#integrations'} color={'var(--white)'} type={'navLink'}>
								Integrations
							</A>
						</Li>
						<Li margin={'0 32px 0 0'}>
							<A href={'#clients'} color={'var(--white)'} type={'navLink'}>
								Clients
							</A>
						</Li>
						<Li>
							<Button type={'button'} theme={'purple'} size={'small'} margin={'0 0 -21px 0'}>
								Contact Us
							</Button>
						</Li>
					</InlineUl>
				</FlexDiv>
			</ContentContainer>
		</NavWrap>
	)
}

const NavWrap = styled.nav`
  	display: flex;
  	align-items: flex-end; 
  	justify-content: space-between;
  	height: 68px;
  	position: fixed;
  	top: 0;
  	right: 0;
  	left: 0;
  	z-index: 10000;
  	transition: background-color .2s var(--standard-easing);
  	@media only screen and (max-width: 720px) {
        
  	}
`