// DOCS: https://styled-system.com/table
import styled from 'styled-components'
import {background, border, color, flexbox, layout, position, space, shadow} from 'styled-system';

const Div = styled.div`
  box-sizing: border-box;
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${shadow}
`

export default Div;
