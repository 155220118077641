import styled from 'styled-components'
import FooterBackground from "../../svgs/FooterBackground";
import Div from "../../atoms/Div";
import FlexDiv from "../../atoms/FlexDiv";
import H1 from "../../atoms/H1";
import Span from "../../atoms/Span";
import Button from "../../atoms/Button";
import P from "../../atoms/P";
import DecorativeStar from "../../svgs/DecorativeStar";

export default function Footer() {
	return (
		<FooterWrap>
			<FlexDiv height={'408px'} justifyContent={'center'} alignItems={'center'} position={'relative'}>
				<FooterBackground/>
				<FooterBackgroundOverlay/>
				<Div maxWidth={'1232px'} width={'100%'} height={'273px'} backgroundColor={'var(--pink)'} borderRadius={'24px'} position={'absolute'} zIndex={'100'}>
					<H1 color={'var(--white)'} textAlign={'center'} fontSize={'30px'} margin={'45px 0 0 0'}>
						Ready to Scale Your e-commerce Business?
					</H1>
					<P color={'var(--white)'} textAlign={'center'} fontSize={'24px'} margin={'30px 0 0 0'}>
						Take the first step
					</P>
					<FlexDiv justifyContent={'center'} margin={'33px 0 0 0'}>
						<Button type={'button'} theme={'outlinedLight'} size={'small'}>
							contact us
						</Button>
					</FlexDiv>
					<Star width={'23px'} height={'28px'} top={'13%'} left={'18%'}/>
					<Star width={'7px'} height={'10px'} top={'9%'} left={'48%'}/>
					<Star width={'9px'} height={'12px'} top={'20%'} left={'82%'}/>
					<Star width={'17px'} height={'18px'} top={'40%'} left={'78%'}/>
					<Star width={'15px'} height={'22px'} top={'63%'} left={'40%'}/>
				</Div>
			</FlexDiv>
		</FooterWrap>
	)
}

function Star({width, height, top, left}) {
	return (
		<Div position={'absolute'} top={top} left={left} height={height} width={width}>
			<DecorativeStar />
		</Div>
	)
}

const FooterWrap = styled.footer`
  height: 815px;
  background-color: var(--dark);
`

const FooterBackgroundOverlay = styled.div`
	position: absolute;
  	left: 0;
  	right:0;
  	top: 0;
  	bottom: 0;
  	background-color: var(--overlay-gray);
    opacity: .7;
  	z-index: 1;
`