import styled from 'styled-components'
import ContentDivider from "../../components/molecules/contentDivider";
import ContentContainer from "../../components/atoms/ContentContainer";
import Aside from "../../components/atoms/Aside";
import H1 from "../../components/atoms/H1";
import P from "../../components/atoms/P";
import H2 from "../../components/atoms/H2";
import Button from "../../components/atoms/Button";
import Div from "../../components/atoms/Div";
import RightButtonArrowIcon from "../../components/svgs/RightButtonArrowIcon";
import Img from "../../components/atoms/Img";

import jockUiSrc from '../../assets/images/jockUi@2x.png'
import rugsOriginalSrc from '../../assets/images/rugsOriginalUi@2x.png'

export default function Clients() {
	return (
		<StyledClients id={'clients'}>
			<ContentDivider fillColour={'#FCFFFD'} />
			<ContentContainer>
				<Aside>clients</Aside>
				<H1 margin={'24px 0 0 0'}>
					Merchants Using Aloe Storefront
				</H1>
				<H2 margin={'92px 0 0 0'}>
					Jock Dog Food B2B
				</H2>
				<P margin={'24px 0 0 0'}>
					Afrique required a solution to optimise the ordering process between their operations and
					their 900 business clients, with various problems to solve including communication,
					automating payments and turnaround time.
				</P>
				<Div margin={'32px 0 0 0'}>
					<Button size={'small'} type={'button'} theme={'outlinedDark'}>
						view case study <ButtonIcon/>
					</Button>
				</Div>
				<Div margin={'92px 0 0 0'}>
					<Img width={'100%'} src={jockUiSrc} alt="Jock Dogfood Ui" />
				</Div>
				<H2 margin={'92px 0 0 0'}>
					Rugs original
				</H2>
				<P margin={'24px 0 0 0'}>
					The largest supplier of Persian carpets in South Africa relaunched their site as a
					Progressive Web app with B2B, B2C and in store integrations.
				</P>
				<Div margin={'32px 0 0 0'}>
					<Button size={'small'} type={'button'} theme={'outlinedDark'}>
						view case study <ButtonIcon/>
					</Button>
				</Div>
				<Div margin={'92px 0 240px 0'}>
					<Img width={'100%'} src={rugsOriginalSrc} alt="Rugs Original Ui" />
				</Div>
			</ContentContainer>
		</StyledClients>
	)
}

function ButtonIcon() {
	return (
		<Div width={'24px'} height={'16px'} margin={'10px'} display={'inline'}>
			<RightButtonArrowIcon/>
		</Div>
	)
}

const StyledClients = styled.div`
  	position: relative;
  	background-color: var(--off-white);
`