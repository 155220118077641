import styled, {css} from 'styled-components'
import {space, color, typography, layout} from 'styled-system'

const AsideCss = css`
  font-family: var(--secondary-font);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: var(--pink);
  text-transform: uppercase;
`

const Aside = styled.aside`
  	${AsideCss}
  	${space}
    ${color}
  	${typography}
  	${layout}
`

export default Aside;