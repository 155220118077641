import styled from 'styled-components'
import ContentContainer from "../../components/atoms/ContentContainer";
import LandingIntroductionBackground from "../../components/molecules/LandingIntroductionBackground";
import H1 from "../../components/atoms/H1";
import P from "../../components/atoms/P";
import Span from "../../components/atoms/Span";
import FlexDiv from "../../components/atoms/FlexDiv";
import Button from "../../components/atoms/Button";
import Div from "../../components/atoms/Div";
import HeroImage from '../../assets/images/landingHero@2x.png'
import Ul from "../../components/atoms/Ul";
import Li from "../../components/atoms/Li";

export default function LandingIntroduction() {
	return (
		<StyledLandingIntroduction id={'home'}>
			<LandingIntroductionBackground />
			<ContentContainer padding={'230px 0 0 0'}>
				<H1 color={'var(--white)'} width={'602px'}>
					Say Goodbye to Platform Headaches
				</H1>
				<P color={'var(--white)'} width={'616px'} margin={'40px 0 0 0'}>
					Aloe Storefront is a high performance <Span>progressive web app</Span> built on top of&nbsp;
					<Span>headless Magento.</Span> Using <Span>modern</Span> UX principals, it was built so&nbsp;
					<Span>medium to large</Span> merchants can get to market quickly at a&nbsp;
					<Span>fraction of the cost</Span> without sacrificing&nbsp;
					<Span>quality</Span> or <Span>flexibility</Span>.
				</P>
				<FlexDiv width={'406px'} margin={'40px 0 0 0'} justifyContent={'space-between'}>
					<Button type={'button'} theme={'pink'}>
						Get Started
					</Button>
					<Button type={'button'} theme={'orange'}>
						Book a Demo
					</Button>
				</FlexDiv>
				<HeroImageContainer>
					<Div width={'1033px'} padding={'62px 73px'} backgroundColor={'var(--white)'} borderRadius={'18px'}>
						<Ul>
							<Li>Enterprise performance & scalability</Li>
							<Li>Cost effective & quick to market</Li>
							<Li>A continually optimized platform driven by industry professionals</Li>
							<Li>No additional transactional fees</Li>
							<Li>Modern design that delivers a unique shopping experience</Li>
							<Li>Mobile Optimised UX</Li>
							<Li>Proactive error and service level alerting</Li>
							<Li>Fixed start up and monthly costs</Li>
						</Ul>
					</Div>
				</HeroImageContainer>
				<FlexDiv padding={'228px 0 0 0'} justifyContent={'flex-end'}>
					<Div>
						<H1 color={'var(--white)'} width={'616px'}>
							Spend More Time With Your Customers
						</H1>
						<P color={'var(--white)'} width={'616px'} margin={'40px 0 0 0'}>
							Our <Span>expert team</Span> takes care of  technology <Span>strategy</Span>, best practice&nbsp;
							and features. An out of the box <Span>headless</Span> solution, around the clock&nbsp;
							<Span>support</Span> and an <Span>innovative</Span> roadmap of features to&nbsp;
							<Span>drive sales and support marketing</Span>.
						</P>
					</Div>
				</FlexDiv>
			</ContentContainer>
		</StyledLandingIntroduction>
	)
}

const StyledLandingIntroduction = styled.div`
  	position: relative;
  	height: 3216px;
`

const HeroImageContainer = styled.div`
	max-height: 637px;
  	width: 103%;
  	height: 100%;
  	margin: 443px -18px 0 -18px;
  	background-image: url(${HeroImage});
  	background-repeat: no-repeat;
  	background-size: cover;
  
  	display: flex;
  	align-items: center;
  	justify-content: center;
`