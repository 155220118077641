import styled from 'styled-components'
import ContentContainer from "../../components/atoms/ContentContainer";
import Aside from "../../components/atoms/Aside";
import H1 from "../../components/atoms/H1";
import P from "../../components/atoms/P";
import FlexDiv from "../../components/atoms/FlexDiv";
import Div from "../../components/atoms/Div";

import aramexLogoSrc from '../../assets/images/logos/aramexLogo@2x.png'
import cloudinaryLogoSrc from '../../assets/images/logos/cloudinaryLogo@2x.png'
import contentfulLogoSrc from '../../assets/images/logos/contentfulLogo@2x.png'
import facebookSignInLogoSrc from '../../assets/images/logos/facebookSignInLogo@2x.png'
import firebaseLogoSrc from '../../assets/images/logos/firebaseLogo@2x.png'
import googleAnalyticsLogoSrc from '../../assets/images/logos/googleAnalyticsLogo@2x.png'
import googleSignInLogo from '../../assets/images/logos/googleSignInLogo@2x.png'
import magentoLogoSrc from '../../assets/images/logos/magentoLogo@2x.png'
import mobicredLogoSrc from '../../assets/images/logos/mobicredLogo@2x.png'
import ozowLogoSrc from '../../assets/images/logos/ozowLogo@2x.png'
import pargoLogoSrc from '../../assets/images/logos/pargoLogo@2x.png'
import payFastLogoSrc from '../../assets/images/logos/payFastLogo@2x.png'
import payuLogoSrc from '../../assets/images/logos/payuLogo@2x.png'
import sageLogoSrc from '../../assets/images/logos/sageLogo@2x.png'
import samsungpayLogoSrc from '../../assets/images/logos/samsungPayLogo@2x.png'
import slackLogoSrc from '../../assets/images/logos/slackLogo@2x.png'
import whatsappLogoSrc from '../../assets/images/logos/whatsappLogo@2x.png'
import zapperLogoSrc from '../../assets/images/logos/zapperLogo@2x.png'
import Img from "../../components/atoms/Img";

export default function Integrations() {
	return (
		<StyledIntegrations id={'integrations'}>
			<ContentContainer padding={'235px 0 430px 0'}>
				<Aside>
					integrations
				</Aside>
				<H1 margin={'24px 0 0 0'} color={'var(--white)'}>
					Launch Faster Without Compromises
				</H1>
				<P margin={'12px 0 0 0'} color={'var(--white)'}>
					A relationship between your brand & the customer that does not keep
					hours. Use our selection of pre-built, and growing integrations with
					other market leaders to give you insight into your commerce journey.
				</P>
				<IntegrationExamples/>
			</ContentContainer>
		</StyledIntegrations>
	)
}

function IntegrationExamples() {
	return (
		<>
			<FlexDiv
				height={'89px'}
				backgroundColor={'var(--white)'}
				borderRadius={'12px'}
				justifyContent={'center'}
				alignItems={'center'}
				margin={'60px 0 0 0'}
			>
				<Img
					src={magentoLogoSrc}
					alt="Magento Logo"
					width={'172px'}
					height={'50px'}
				/>
			</FlexDiv>
			<FlexDiv justifyContent={'space-between'}>
				<Div width={'577px'}>
					<P color={'var(--white)'} margin={'61px 0 28px 0'} textAlign={'center'}>
						Integrations
					</P>
					<FlexDiv
						backgroundColor={'var(--white)'}
						borderRadius={'12px'}
						height={'330px'}
						alignItems={'center'}
						justifyContent={'center'}
					>
						<GridItem>
							<LogoImage
								src={cloudinaryLogoSrc}
								alt="Cloudinary Logo"
								width={'178px'}
								height={'34px'}
							/>
							<LogoImage
								src={slackLogoSrc}
								alt="Slack Logo"
								width={'140px'}
								height={'35px'}
							/>
							<LogoImage
								src={googleAnalyticsLogoSrc}
								alt="Google Analytics Logo"
								width={'164px'}
								height={'82px'}
							/>
							<LogoImage
								src={firebaseLogoSrc}
								alt="Firebase Logo"
								width={'164px'}
								height={'46px'}
							/>
							<LogoImage
								src={sageLogoSrc}
								alt="Sage Logo"
								width={'102px'}
								height={'39px'}
							/>
						</GridItem>
					</FlexDiv>
				</Div>
				<Div width={'577px'}>
					<P color={'var(--white)'} margin={'61px 0 28px 0'} textAlign={'center'}>
						Payments
					</P>
					<FlexDiv
						backgroundColor={'var(--white)'}
						borderRadius={'12px'}
						height={'330px'}
						alignItems={'center'}
						justifyContent={'center'}
					>
						<GridItem>
							<LogoImage
								src={zapperLogoSrc}
								alt="Zapper Logo"
								width={'142px'}
								height={'52px'}
							/>
							<LogoImage
								src={ozowLogoSrc}
								alt="Ozow Logo"
								width={'137px'}
								height={'50px'}
							/>
							<LogoImage
								src={payFastLogoSrc}
								alt="PayFast Logo"
								width={'138px'}
								height={'46px'}
							/>
							<LogoImage
								src={mobicredLogoSrc}
								alt="Mobicred Logo"
								width={'147px'}
								height={'46px'}
							/>
							<LogoImage
								src={payuLogoSrc}
								alt="PayU Logo"
								width={'86px'}
								height={'43px'}
							/>
							<LogoImage
								src={samsungpayLogoSrc}
								alt="Samsung Pay Logo"
								width={'190px'}
								height={'34px'}
							/>
						</GridItem>
					</FlexDiv>
				</Div>
			</FlexDiv>
			<FlexDiv justifyContent={'space-between'}>
				<Div width={'577px'}>
					<P color={'var(--white)'} margin={'61px 0 28px 0'} textAlign={'center'}>
						3rd Party
					</P>
					<FlexDiv
						backgroundColor={'var(--white)'}
						borderRadius={'12px'}
						height={'237px'}
						alignItems={'center'}
						justifyContent={'center'}
					>
						<GridItem>
							<LogoImage
								src={whatsappLogoSrc}
								alt="Whatsapp Logo"
								width={'158px'}
								height={'45px'}
							/>
							<LogoImage
								src={googleSignInLogo}
								alt="google sign in Logo"
								width={'208px'}
								height={'42px'}
							/>
							<LogoImage
								src={contentfulLogoSrc}
								alt="Contentful Logo"
								width={'157px'}
								height={'32px'}
							/>
							<LogoImage
								src={facebookSignInLogoSrc}
								alt="Facebook Logo"
								width={'208px'}
								height={'42px'}
							/>
						</GridItem>
					</FlexDiv>
				</Div>
				<Div width={'577px'}>
					<P color={'var(--white)'} margin={'61px 0 28px 0'} textAlign={'center'}>
						Delivery
					</P>
					<FlexDiv
						backgroundColor={'var(--white)'}
						borderRadius={'12px'}
						height={'237px'}
						alignItems={'center'}
						justifyContent={'center'}
					>
						<GridItem>
							<LogoImage
								src={aramexLogoSrc}
								alt="Aramex Logo"
								width={'146px'}
								height={'41px'}
							/>
							<LogoImage
								src={pargoLogoSrc}
								alt="Pargo Logo"
								width={'66px'}
								height={'66px'}
							/>
						</GridItem>
					</FlexDiv>
				</Div>
			</FlexDiv>
		</>
	)
}

function LogoImage({src, alt, width, height}) {
	return (
		<FlexDiv display={'inline'} justifyContent={'center'} alignItems={'center'}>
			<Img
				src={src}
				alt={alt}
				width={width}
				height={height}
			/>
		</FlexDiv>
	)
}

const StyledIntegrations = styled.div`
  	background-color: var(--dark);
`

const GridItem = styled.div`
	display: grid;
  	grid-template-columns: repeat(2, 1fr);
  	grid-gap: 30px;
`