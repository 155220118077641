import styled, {css} from 'styled-components'
import {space, color, typography, layout} from 'styled-system'

const H2Css = css`
  margin: 0;
  font-family: var(--font);
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.05em;
  color: var(--text-dark);
`

const H2 = styled.h2`
  	${H2Css}
  	${space}
    ${color}
  	${typography}
  	${layout}
`

export default H2;